<template>
    <div class="bg-yellow-50">
      <div class="max-w-4xl mx-auto p-6 space-y-6">
        <!-- 新增文章输入区 -->
        <div class="border-b border-yellow-200 pb-6 space-y-3">
          <input
            v-model="newTitle"
            placeholder="输入文章标题..."
            class="block w-full p-3 bg-yellow-50 border border-yellow-200 rounded-lg focus:ring-1 focus:ring-yellow-300 focus:border-yellow-300 placeholder-yellow-700/30"
          />
          <textarea
            v-model="newArticle"
            placeholder="写下你的新文章内容..."
            class="resize-y w-full h-32 p-3 bg-yellow-50 border border-yellow-200 rounded-lg focus:ring-1 focus:ring-yellow-300 focus:border-yellow-300 placeholder-yellow-700/30"
          />
          <button
            @click="saveArticle"
            class="mt-3 px-4 py-2 bg-yellow-700/90 hover:bg-yellow-800 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2"
          >
            <PencilIcon class="h-5 w-5" />
            <span>保存文章</span>
          </button>
        </div>
  
        <!-- 搜索区 -->
        <div class="border-b border-yellow-200 pb-6">
          <div class="flex space-x-2">
            <div class="relative flex-1">
              <input
                v-model="searchTerm"
                type="text"
                placeholder="搜索文章..."
                class="w-full pl-10 pr-4 py-2 bg-yellow-50 border border-yellow-200 rounded-lg focus:ring-1 focus:ring-yellow-300 focus:border-yellow-300 placeholder-yellow-700/30"
              />
              <MagnifyingGlassIcon class="h-5 w-5 absolute left-3 top-2.5 text-yellow-700/50" />
            </div>
            <button
              @click="searchArticles(1)"
              class="px-6 py-2 bg-yellow-600/70 hover:bg-yellow-700/70 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2 whitespace-nowrap"
            >
              <MagnifyingGlassIcon class="h-5 w-5" />
              <span>搜索</span>
            </button>
          </div>
        </div>
  
        <!-- 文章列表 -->
        <div class="space-y-4">
          <!-- 加载状态 -->
          <div v-if="loading" class="text-center py-8">
            <div
              class="inline-block animate-spin rounded-full h-8 w-8 border-4 border-yellow-700/50 border-t-transparent"
            ></div>
            <p class="mt-2 text-yellow-800/70">加载中...</p>
          </div>
  
          <!-- 有文章数据时 -->
          <div v-else-if="Array.isArray(articles) && articles.length > 0" class="space-y-2">
            <div
              v-for="article in articles"
              :key="article.id"
              class="py-4 border-b border-yellow-200 hover:bg-yellow-100/30 transition duration-200 ease-in-out"
            >
              <!-- 标题（可点击进入详情） -->
              <router-link :to="`/article/${article.id}`" class="block">
                <h2 class="text-yellow-900/90 text-xl font-semibold">
                  {{ article.title }}
                </h2>
              </router-link>
  
              <!-- 时间 -->
              <div class="mt-2 text-sm text-yellow-700/70 flex items-center space-x-2">
                <ClockIcon class="h-4 w-4" />
                <span>{{ new Date(article.published_at).toLocaleString() }}</span>
              </div>
            </div>
          </div>
  
          <!-- 没有文章时 -->
          <div v-else class="text-center py-8 text-yellow-700/70 border-b border-yellow-200">
            没有找到文章
          </div>
        </div>
  
        <!-- 分页 -->
        <div class="flex items-center justify-center space-x-4 pt-2" v-if="showPagination">
          <button
            @click="handlePageChange(currentPage - 1)"
            :disabled="currentPage === 1"
            class="px-4 py-2 rounded-lg border border-yellow-300 hover:bg-yellow-100/30 disabled:opacity-50 disabled:cursor-not-allowed transition duration-200 ease-in-out flex items-center space-x-2"
          >
            <ChevronLeftIcon class="h-5 w-5 text-yellow-700" />
            <span class="text-yellow-700">上一页</span>
          </button>
  
          <span class="text-yellow-800/70">
            第 {{ currentPage }} 页
          </span>
  
          <button
            @click="handlePageChange(currentPage + 1)"
            :disabled="!hasNextPage"
            class="px-4 py-2 rounded-lg border border-yellow-300 hover:bg-yellow-100/30 disabled:opacity-50 disabled:cursor-not-allowed transition duration-200 ease-in-out flex items-center space-x-2"
          >
            <span class="text-yellow-700">下一页</span>
            <ChevronRightIcon class="h-5 w-5 text-yellow-700" />
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { get, post } from '../utils';
  
  import {
    PencilIcon,
    MagnifyingGlassIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon
  } from '@heroicons/vue/24/outline';
  
  const router = useRouter();
  
  const searchTerm = ref('');
  const articles = ref([]);
  const loading = ref(false);
  
  // 新增文章时的输入
  const newTitle = ref('');    // 新增：文章标题
  const newArticle = ref('');  // 新增：文章正文
  
  // 分页相关
  const currentPage = ref(1);
  const pageSize = ref(10);
  const hasNextPage = ref(false);
  const showPagination = ref(false);
  
  // 检查登录
  onMounted(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      router.push('/login');
    } else {
      loadArticles(currentPage.value);
    }
  });
  
  // 加载文章列表
  const loadArticles = async (page = 1) => {
    loading.value = true;
    try {
      const response = await get(`/api/article/list?page=${page}&pageSize=${pageSize.value}`);
      if (Array.isArray(response)) {
        articles.value = response;
        // 是否还有下一页
        hasNextPage.value = response.length === pageSize.value;
        // 显示分页
        showPagination.value = true;
      } else {
        console.error('预期的响应是数组，但收到:', response);
        articles.value = [];
        hasNextPage.value = false;
        showPagination.value = false;
      }
    } catch (error) {
      console.error('加载文章失败:', error);
      articles.value = [];
      hasNextPage.value = false;
      showPagination.value = false;
    } finally {
      loading.value = false;
    }
  };
  
  // 搜索文章
  const searchArticles = async (page = 1) => {
    loading.value = true;
    try {
      const response = await get(
        `/api/article/search?page=${page}&pageSize=${pageSize.value}&search=${encodeURIComponent(searchTerm.value)}`
      );
      if (Array.isArray(response)) {
        articles.value = response;
        hasNextPage.value = response.length === pageSize.value;
        showPagination.value = true;
      } else {
        console.error('预期的响应是数组，但收到:', response);
        articles.value = [];
        hasNextPage.value = false;
        showPagination.value = false;
      }
    } catch (error) {
      console.error('搜索文章失败:', error);
      articles.value = [];
      hasNextPage.value = false;
      showPagination.value = false;
    } finally {
      loading.value = false;
    }
  };
  
  // 保存文章（新增）
  const saveArticle = async () => {
    if (!newTitle.value.trim() || !newArticle.value.trim()) {
      // 若需要强制要求标题和内容都不能为空，可以在这里进行判断
      alert('请填写文章标题和内容');
      return;
    }
    loading.value = true;
    try {
      await post('/api/article/add', {
        title: newTitle.value,      // 使用 newTitle 作为标题
        content: newArticle.value   // 使用 newArticle 作为正文
      });
      // 清空输入框
      newTitle.value = '';
      newArticle.value = '';
      // 重新加载当前页
      loadArticles(currentPage.value);
    } catch (error) {
      console.error('保存文章失败:', error);
    } finally {
      loading.value = false;
    }
  };
  
  // 分页
  const handlePageChange = (newPage) => {
    if (newPage < 1) return;
    currentPage.value = newPage;
    if (searchTerm.value) {
      searchArticles(newPage);
    } else {
      loadArticles(newPage);
    }
  };
  
  // 如果需要监听 currentPage 的变化，也可以用 watch
  watch(currentPage, (newVal) => {
    if (searchTerm.value) {
      searchArticles(newVal);
    } else {
      loadArticles(newVal);
    }
  });
  </script>
  
  <style scoped>
  /* 可根据需要添加样式 */
  </style>
  