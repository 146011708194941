<template>
  <div class="bg-yellow-50">
    <div class="max-w-4xl mx-auto p-6">
      <!-- 返回按钮 -->
      <div class="flex items-center justify-between border-b border-yellow-200 pb-6 mb-6">
        <button
          @click="goBack"
          class="px-4 py-2 bg-yellow-600/70 hover:bg-yellow-700/70 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2"
        >
          <ArrowLeftIcon class="h-5 w-5" />
          <span>返回列表</span>
        </button>
      </div>

      <!-- 加载状态 -->
      <div v-if="loading" class="text-center py-8">
        <div
          class="inline-block animate-spin rounded-full h-8 w-8 border-4 border-yellow-700/50 border-t-transparent"
        ></div>
        <p class="mt-2 text-yellow-800/70">加载中...</p>
      </div>

      <!-- 文章内容 -->
      <div v-else-if="article" class="space-y-6">
        <!-- 编辑模式 -->
        <div v-if="editing" class="space-y-4">
          <!-- 标题输入 -->
          <div>
            <label class="block text-sm text-yellow-700/70 mb-1">文章标题</label>
            <input
              v-model="editedTitle"
              placeholder="文章标题"
              class="block w-full p-3 bg-yellow-50 border border-yellow-200 rounded-lg focus:ring-1 focus:ring-yellow-300 focus:border-yellow-300 transition duration-200 ease-in-out"
            />
          </div>
          <!-- 正文编辑框 -->
          <div>
            <label class="block text-sm text-yellow-700/70 mb-1">文章内容</label>
            <textarea
              v-model="editedContent"
              placeholder="文章内容"
              class="w-full p-3 bg-yellow-50 border border-yellow-200 rounded-lg focus:ring-1 focus:ring-yellow-300 focus:border-yellow-300 resize-y transition duration-200 ease-in-out min-h-[200px]"
            />
          </div>
          <!-- 发布时间输入 -->
          <div>
            <label class="block text-sm text-yellow-700/70 mb-1">发布时间（YYYY-MM-DD HH:MM:SS）</label>
            <input
              v-model="editedPublishedAt"
              placeholder="2025-01-14 16:30:00"
              class="block w-full p-3 bg-yellow-50 border border-yellow-200 rounded-lg focus:ring-1 focus:ring-yellow-300 focus:border-yellow-300 transition duration-200 ease-in-out"
            />
          </div>

          <!-- 按钮 -->
          <div class="flex space-x-4">
            <button
              @click="saveArticle"
              class="px-4 py-2 bg-yellow-700/90 hover:bg-yellow-800 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2"
              :disabled="loading"
            >
              <CheckIcon class="h-5 w-5" />
              <span>保存</span>
            </button>
            <button
              @click="cancelEdit"
              class="px-4 py-2 bg-yellow-600/70 hover:bg-yellow-700/70 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2"
              :disabled="loading"
            >
              <XMarkIcon class="h-5 w-5" />
              <span>取消</span>
            </button>
          </div>
        </div>

        <!-- 展示模式 -->
        <div v-else class="space-y-4">
          <div class="border-b border-yellow-200 pb-6">
            <h2 class="text-yellow-900/90 text-2xl font-semibold">
              {{ article.title }}
            </h2>
            <p class="text-yellow-900/90 text-base mt-2 whitespace-pre-wrap">
              {{ article.content }}
            </p>
            <!-- 这里将 created_at 改为 published_at -->
            <p class="mt-2 text-sm text-yellow-700/70 flex items-center space-x-1">
              <ClockIcon class="h-4 w-4" />
              <span>{{ formatDate(article.published_at) }}</span>
            </p>
          </div>

          <div class="flex space-x-4">
            <button
              @click="editArticle"
              class="px-4 py-2 bg-yellow-600/70 hover:bg-yellow-700/70 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2"
            >
              <PencilSquareIcon class="h-5 w-5" />
              <span>编辑</span>
            </button>
            <button
              @click="deleteArticle"
              :disabled="deleting"
              class="px-4 py-2 bg-red-600/70 hover:bg-red-700/70 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2"
            >
              <TrashIcon class="h-5 w-5" />
              <span>{{ deleting ? '删除中...' : '删除' }}</span>
            </button>
          </div>
        </div>
      </div>

      <!-- 未找到文章 -->
      <div v-else class="text-center py-8">
        <ExclamationCircleIcon class="h-12 w-12 mx-auto text-yellow-700/50 mb-2" />
        <p class="text-yellow-700/70">未找到文章。</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { get, post } from '../utils';

import {
  ArrowLeftIcon,
  PencilSquareIcon,
  PencilIcon,
  TrashIcon,
  CheckIcon,
  XMarkIcon,
  ClockIcon,
  ExclamationCircleIcon
} from '@heroicons/vue/24/outline';

const router = useRouter();
const route = useRoute();

// 文章数据与状态
const article = ref(null);
const loading = ref(false);
const deleting = ref(false);
const editing = ref(false);

// 编辑时的临时数据
const editedTitle = ref('');
const editedContent = ref('');
const editedPublishedAt = ref(''); // 新增，用于编辑发布时间

// 当前文章ID
const id = route.params.id;

// 1. 加载文章详情
const fetchArticle = async () => {
  loading.value = true;
  try {
    const token = localStorage.getItem('token');
    const data = await get(`/api/article/detail?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // 将获取到的文章存入 article
    article.value = data;

    // 准备编辑时的初始值
    editedTitle.value = data.title;
    editedContent.value = data.content;

    // 如果后端有存储 published_at，则赋值给前端
    // 若后端原先没这个字段，且新增加了，需要确保后端返回了 published_at
    editedPublishedAt.value = data.published_at || '';
  } catch (error) {
    console.error('获取文章详情失败:', error);
  } finally {
    loading.value = false;
  }
};

// 2. 保存编辑（更新文章）
const saveArticle = async () => {
  loading.value = true;
  try {
    const token = localStorage.getItem('token');
    await post(
      '/api/article/edit',
      {
        id,
        title: editedTitle.value,
        content: editedContent.value,
        // 传递 published_at 到后端
        published_at: editedPublishedAt.value
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // 更新本地状态
    if (article.value) {
      article.value.title = editedTitle.value;
      article.value.content = editedContent.value;
      article.value.published_at = editedPublishedAt.value;
    }
    editing.value = false;
  } catch (error) {
    console.error('保存文章失败:', error);
  } finally {
    loading.value = false;
  }
};

// 3. 删除文章
const deleteArticle = async () => {
  if (!confirm('确定要删除这篇文章吗？')) return;
  deleting.value = true;
  try {
    const token = localStorage.getItem('token');
    await post(
      '/api/article/delete',
      { id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );
    // 删除后跳转回列表
    router.push('/articles');
  } catch (error) {
    console.error('删除文章失败:', error);
  } finally {
    deleting.value = false;
  }
};

// 4. 格式化日期（如果 published_at 为空，则返回“无”或其他提示）
const formatDate = (dateStr) => {
  if (!dateStr) return '无';
  return new Date(dateStr).toLocaleString();
};

// 5. 返回到列表页
const goBack = () => {
  router.push('/articles');
};

// 6. 进入编辑模式
const editArticle = () => {
  editing.value = true;
};

// 7. 取消编辑
const cancelEdit = () => {
  editing.value = false;
  if (article.value) {
    editedTitle.value = article.value.title;
    editedContent.value = article.value.content;
    editedPublishedAt.value = article.value.published_at || '';
  }
};

// 页面挂载时加载文章详情
onMounted(() => {
  fetchArticle();
});
</script>

<style scoped>
/* 根据需要添加或修改样式 */
</style>
