import { createRouter, createWebHistory } from 'vue-router';
import Notes from '../views/Notes.vue';
import NoteDetail from '../views/NoteDetail.vue';
import Articles from '../views/Articles.vue';
import ArticleDatail from '../views/ArticleDatail.vue';
const routes = [
  { path: '/', redirect: '/notes' },
  { path: '/notes', component: Notes },
  {
    path: '/note/:id',
    component: NoteDetail,
    props: true,
  },
  { path: '/articles', component: Articles },
  {
    path: '/article/:id',
    component: ArticleDatail,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
