<template>
  <div id="app" class="flex flex-col" style="height: 100vh;">

    <!-- 登录注册卡片 -->
    <LoginRegisterCard v-if="!isLoggedIn" @loginSuccess="handleLoginSuccess" />

    <!-- 导航栏 -->
    <nav v-if="isLoggedIn" class="bg-gray-800 p-4 flex items-center justify-between h-14 relative z-50">
      <!-- 导航菜单 -->
      <div class="flex space-x-6">
        <router-link 
          v-for="item in navItems" 
          :key="item.name" 
          :to="item.to" 
          class="text-white hover:text-blue-500"
          :class="{ 'text-blue-500': currentTab === item.name }" 
          @click="setTab(item.name)"
        >
          {{ item.name }}
        </router-link>
      </div>

      <!-- 右侧：用户图标 -->
      <div class="flex items-center">
        <img 
          src="https://via.placeholder.com/40" 
          alt="User Avatar" 
          @click="toggleUserMenu"
          class="w-8 h-8 rounded-full cursor-pointer"
        >

        <!-- 用户菜单 -->
        <div v-if="isUserMenuOpen" class="absolute right-4 w-48 bg-white rounded-md shadow-lg z-20 top-14">
          <div class="px-4 py-2 border-b text-gray-800">{{ username }}</div>
          <button 
            @click="logout" 
            class="w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100"
          >
            退出登录
          </button>
        </div>
      </div>
    </nav>

    <!-- 路由视图 -->
    <router-view v-if="isLoggedIn" class="flex-grow overflow-auto"></router-view>

  </div>
</template>

<script>
import LoginRegisterCard from './components/LogSig.vue';

export default {
  name: 'mindbase',
  components: {
    LoginRegisterCard
  },
  data() {
    return {
      isUserMenuOpen: false,
      currentTab: '记录',
      isLoggedIn: false,
      username: '',
      // 导航项，仅包含名称和路由路径
      navItems: [
        { name: '记录', to: '/notes' },
        { name: '文章', to: '/articles' },
      ],
    };
  },
  methods: {
    toggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    setTab(tab) {
      this.currentTab = tab;
    },
    handleLoginSuccess(username) {
      this.username = username;
      this.isLoggedIn = true;
    },
    logout() {
      this.username = '';
      this.isLoggedIn = false;
      localStorage.removeItem('token');
    }
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      this.username = 'User'; 
      this.isLoggedIn = true;
    }
  }
};
</script>

<style scoped>
/* 自定义样式 */
</style>
